import {Component, Input, OnInit} from '@angular/core';
import {EmployeePivot} from '../../../../../core/models/employee-pivot';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  @Input() employeeData?: EmployeePivot;

  constructor() { }

  ngOnInit(): void {

    console.log(this.employeeData);
  }

}
