<div class="row">
    <div class="col-12 hrep-headline d-flex align-items-center mb-4">
        <i class="icon-doc me-2"></i>
        <h6 class="small fw-bold mb-0">{{ 'CUSTOMER.ADMINISTRATION' | translate }}</h6>
    </div>
    <div class="hrep-accordion accordion-light mb-5">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CUSTOMER.COST_CENTER' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='costCenters' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>

                                <e-column field='code' headerText="{{ 'CUSTOMER.NO' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column field='name' headerText="{{ 'CUSTOMER.DESCRIPTION' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column width='130' headerText="{{ 'CUSTOMER.STATUS' | translate }}" textAlign='Center'>
                                    <ng-template #template let-data class="p-0">
                                        <ejs-dropdownlist id='status' class="hrep-dropdown suffix-filled" [dataSource]='statuses' [value]="data.status" [text]="data.status" [fields]='fields' cssClass="hrep-dropdown no-margin-dropdown"
                                        ></ejs-dropdownlist>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>

    <div class="hrep-accordion accordion-light mb-5">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CUSTOMER.WORK_MODEL' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='workingModels' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='name' headerText="{{ 'CUSTOMER.DESCRIPTION' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column width='130' headerText="{{ 'CUSTOMER.STATUS' | translate }}" textAlign='Center'>
                                    <ng-template #template let-data class="p-0">
                                        <ejs-dropdownlist id='status' class="hrep-dropdown suffix-filled" [dataSource]='statuses' [value]="data.status" [text]="data.status" [fields]='fields' cssClass="hrep-dropdown no-margin-dropdown"
                                        ></ejs-dropdownlist>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>

    <div class="hrep-accordion accordion-light mb-5">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CUSTOMER.EMPLOYEE' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='employees' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='first_name' headerText="{{ 'CUSTOMER.FIRST_NAME' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column field='last_name' headerText="{{ 'CUSTOMER.LAST_NAME' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column field='position' headerText="{{ 'CUSTOMER.POSITION' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>

    <div class="hrep-accordion accordion-light mb-5">
        <ejs-accordion>
            <e-accordionitems>
                <e-accordionitem expanded='true'>
                    <ng-template #header>
                        <div>{{ 'CUSTOMER.PROFESSIONS' | translate }}</div>
                    </ng-template>
                    <ng-template #content>
                        <ejs-grid class="hrep-grid" [dataSource]='professions' [enableHover]='false' [rowHeight]='30'
                                  gridLines='Both'>
                            <e-columns>
                                <e-column field='name' headerText="{{ 'CUSTOMER.NAME' | translate }}" textAlign='Center'
                                          width='100'></e-column>
                                <e-column width='130' headerText="{{ 'CUSTOMER.STATUS' | translate }}" textAlign='Center'>
                                    <ng-template #template let-data class="p-0">
                                        <ejs-dropdownlist class="hrep-dropdown suffix-filled" id='status' [dataSource]='statuses' [value]="data.status" [text]="data.status" [fields]='fields' cssClass="hrep-dropdown no-margin-dropdown"
                                        ></ejs-dropdownlist>
                                    </ng-template>
                                </e-column>
                            </e-columns>
                        </ejs-grid>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
</div>
