<nav class="navbar navbar-expand-lg navbar-dark hrep-menu">
  <div class="container-fluid px-3">
    <button class="navbar-toggler p-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a routerLink="dashboard" class="navbar-brand logo-mobile logo-small"><img class="img-fluid" src="assets/layout/images/logo/logo-small.png"/></a>
    <div class="e-avatar e-avatar-small e-avatar-circle">
      <img src="{{current_user?.image}}"/>
    </div>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/dashboard' ? 'active' : ''}}" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/supervisor' ? 'active' : ''}}" href="/supervisor">Ansprechpartner</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/profile' ? 'active' : ''}}" href="/profile">Profil</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/administration' ? 'active' : ''}}" href="/administration">Admin</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/messages' ? 'active' : ''}}" href="/messages">Mitteilungen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link {{currentRoute === '/faq' ? 'active' : ''}}" href="/faq">Hilfe</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
