import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrudService} from '../../../../core/services/crud.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {CostCenter} from '../../../../core/models/cost-center';
import {WorkingModel} from '../../../../core/models/working-model';
import {Employee} from '../../../../core/models/employee';
import {Profession} from '../../../../core/models/profession';
import {User} from '../../../../core/models/user';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationComponent implements OnInit {
    public costCenters: CostCenter[] = [];
    public workingModels: WorkingModel[] = [];
    public employees: User[] = [];
    public professions: Profession[] = [];
    public fields: any = {text: 'name', value: 'name'};
    public statuses: { [key: string]: any }[] = [
        {name: 'active'},
        {name: 'inactive'}
    ];

    constructor(
        private crudService: CrudService,
        private cd: ChangeDetectorRef,
        private authService: AuthenticationService,
    ) {
    }

    ngOnInit(): void {
        this.authService.getLoggedUser().subscribe(user => {
            this.crudService.fetchEntities<CostCenter>('cost_center', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.costCenters = response.data;
                        this.cd.detectChanges();
                    }
                );

            this.crudService.fetchEntities<WorkingModel>('working_model', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.workingModels = response.data;
                        this.cd.detectChanges();
                    }
                );

            this.crudService.fetchEntities<User>('user', {'filter[customer_id]': user.customer?.id || 'x'}).subscribe(response => {
                    this.employees = response.data;
                    this.cd.detectChanges();
                }
            );

            this.crudService.fetchEntities<Profession>('profession', {'filter[customer_id]': user.customer?.id || 'x'})
                .subscribe(response => {
                        this.professions = response.data;
                        this.cd.detectChanges();
                    }
                );
        });
    }

}

