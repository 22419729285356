<div class='content'>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="hrep-accordion accordion-light text-start">
                    <ejs-accordion>
                        <e-accordionitems>
                            <e-accordionitem expanded='true'>
                                <ng-template #header>
                                    <div>{{ 'CUSTOMER.DIALOG_SUBTITLE' | translate }}</div>
                                </ng-template>
                                <ng-template #content>
                                    <div class="row pb-2">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="row">
                                                    <div class="col-4 d-flex mb-3">
                                                        <label>{{ 'CUSTOMER.PERS_NUM' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.id"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label>{{ 'CUSTOMER.GENDER' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.gender"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label>{{ 'CUSTOMER.FIRST_NAME' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.first_name"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label>{{ 'CUSTOMER.LAST_NAME' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.last_name"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-4 d-flex align-items-center mb-3">
                                                        <label>{{ 'CUSTOMER.PROFESSION' | translate }}</label>
                                                        <div class='textboxes ms-3'>
                                                            <ejs-textbox [readonly]="true"
                                                                         [value]="employeeData?.employee?.position"
                                                                         cssClass="hrep-input"></ejs-textbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="hrcp-dv">
                                            <span class="pe-2 me-1">Einsätze</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </e-accordionitem>

                        </e-accordionitems>

                    </ejs-accordion>
                </div>

            </div>
        </div>
    </div>
</div>

