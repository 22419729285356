import {ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DashboardItem} from '../../../../core/models/dashboard-item';
import {User} from '../../../../core/models/user';
import {AuthenticationService} from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-content',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {
  public mainActions: DashboardItem[] = [];
  public current_user: User | null = null;

  constructor(private translate: TranslateService, private authService: AuthenticationService, private cd: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.authService.getLoggedUser().subscribe(user => {
      this.current_user = user;
      this.mainActions = [
        {
          icon: 'icon-user-following',
          text: this.translate.instant('CUSTOMER.ACTIVE_EMPLOYEES'),
          url: '/employees/active'
        },
        {
          icon: 'icon-user-unfollow',
          text: this.translate.instant('CUSTOMER.FORMER_EMPLOYEES'),
          url: '/employees/former'
        },
        {
          icon: 'icon-user-follow',
          text: this.translate.instant('CUSTOMER.CANDIDATE_EMPLOYEES'),
          url: '/employees/candidate'
        },
        {
          icon: 'icon-magnifier',
          text: this.translate.instant('CUSTOMER.SEARCH'),
          url: '/contracts'
        },
        {
          icon: 'icon-cloud-upload',
          text: this.translate.instant('CUSTOMER.UPLOAD'),
          url: '/vacations'
        },
        {
          icon: 'icon-layers',
          text: this.translate.instant('CUSTOMER.DOWNLOAD'),
          url: '/document-types'
        },
        {
          icon: 'icon-notebook',
          text: this.translate.instant('CUSTOMER.LIST'),
          url: '/tickets'
        },
        {
          icon: 'icon-grid',
          text: this.translate.instant('CUSTOMER.DASHBOARD'),
          url: '/documents-upload'
        },
        {
          icon: 'icon-envelope-letter',
          text: this.translate.instant('CUSTOMER.NEWSLETTER'),
          url: '/documents-upload'
        }
      ];
      this.cd.detectChanges();
    });
  }

}
